<template>
    <div
        class="loading fixed top-0 bottom-0 left-0 right-0 bg-background-secondary transition-opacity flex justify-center items-center"
        :class="{'loaded': props.loaded}"
    >
        <div class="max-w-sm w-1/6">
            <img :src="'/assets/img/'+ brand +'/logo.svg'" class="pb-4 w-full" alt="brand-logo">
            <img :src="logoLink" alt="law-manager-logo">
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';


const brand = process.env.VUE_APP_BRANDING;
const logoLink = computed(() => brand === 'kewlaw' ? '/assets/img/lawManagerLogo-white.svg' : '/assets/img/lawManagerLogo.svg');
const props = defineProps({
    loaded: {
        type: Boolean,
        default: false
    }
})

</script>

<style scoped>
.loading {
    z-index: 1000;
}

.loaded {
    animation: loadfade;
    animation-duration: 1.5s;
}

@keyframes loadfade {
    from {
        opacity: 100%;
        pointer-events: none;
    }

    75% {
        opacity: 100%;
        pointer-events: none;
    }

    to {
        opacity: 0%;
        pointer-events: auto;
    }
}
</style>

export default [
    {
        path: '/',
        name: 'portal',
        component: () => import('@/views/portal/PortalHome.vue'),
        meta: {
            public: false,
        }
    },

    {
        path: '/my-account',
        name: 'my-account',
        component: () => import('@/views/portal/MyAccount.vue'),
        meta: {
            public: false,
        }
    },

    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/portal/PortalRegisterScreen.vue'),
        meta: {
            public: true,
        }
    },

    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/portal/PortalForgotPassword.vue'),
        meta: {
            public: true,
        }
    },

    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/portal/PortalResetPassword.vue'),
        meta: {
            public: true,
        }
    },

    {
        path: '/terms-and-conditions/:url',
        name: 'terms-and-conditions',
        component: () => import('@/views/legal/TermsAndConditions.vue'),
        meta: {
            public: true,
        }
    },

    {
        path: '/privacy-policy/:url',
        name: 'privacy-policy',
        component: () => import('@/views/legal/PrivacyPolicy.vue'),
        meta: {
            public: true,
        }
    },

    {
        path: '/account-created/:email',
        name: 'account-created',
        component: () => import('@/views/portal/AccountCreated.vue'),
        meta: {
            public: false,
        },
    },

    {
        canReuse: false,
        path: '/my-matters/overview/:reference',
        name: 'matter.overview',
        component: () => import('@/views/portal/MatterOverview.vue'),
        meta: {
            public: false,
            isMatterRoute: true,
        }
    },

    {
        canReuse: false,
        path: '/my-matters/tasks/:reference/:group_id',
        name: 'matter.tasks.overview',
        component: () => import('@/views/portal/TasksOverview.vue'),
        meta: {
            public: false,
            isMatterRoute: true,
        }
    },

    {
        path: '/my-matters/documents/:reference',
        name: 'matter.documents.overview',
        component: () => import('@/views/portal/DocumentsOverview.vue'),
        meta: {
            public: false,
            isMatterRoute: true,
        }
    },

    {
        path: '/my-matters/payments/:reference',
        name: 'matter.payments.overview',
        component: () => import('@/views/portal/PaymentsOverview.vue'),
        meta: {
            public: false,
            isMatterRoute: true,
        }
    },

    {
        path: '/my-matters/inbox/:reference',
        name: 'matter.inbox',
        component: () => import('@/views/portal/MatterInbox.vue'),
        meta: {
            public: false,
            isMatterRoute: true,
        }
    },

    {
        path: '/my-matters/deposit/overview/:url',
        name: 'quote.deposit.overview',
        component: () => import('@/views/portal/DepositPayment.vue'),
        meta: {
            public: true,
        }
    },
];
<template>
    <div class="hidden col-span-12 md:col-span-6 md:flex flex-col justify-center items-center space-y-36 relative bg-gradient-primary">
        <svg v-if="brand === 'kewlaw'" xmlns="http://www.w3.org/2000/svg" class="absolute top-0 right-0 h-full w-auto transform translate-x-full" viewBox="0 0 114.9 1440" xml:space="preserve">
            <path d="M0 1440V0l28.7 120c28.7 120 86.2 360 86.2 600s-57.5 480-86.2 600L0 1440z" fill="#E7E9EC"/>
        </svg>
        
        <div class="max-w-lg text-center space-y-8 text-txt-primary">
            <h1 class="text-xl md:text-2xl kl-serif max-w-xs mx-auto">All of your legal matters in one place</h1>
            <p class="text-sm lg:text-base kl-serif-regular mx-auto">At the heart of {{ Brand[brand as keyof typeof Brand] }} is the aim to make our services as seamless, accessible, and transparent as possible for our clients.</p>
        </div>
        <img :src="'/assets/img/'+ brand +'/login-image.svg'" alt="login-logo">
    </div>
</template>
<script setup lang="ts">
import { Brand } from '@/utils/enums/Brand';

const brand = process.env.VUE_APP_BRANDING;
</script>
<template>
    <ion-button 
        :disabled="disabled || saving" 
        :size="size"
        :style="{'--background': themeColor}"
        class="inline-flex align-middle align-items-center justify-center font-medium focus:outline-none border-2"
    >
        <fa-icon 
            v-if="saving"
            :icon="['far', 'spinner-third']" 
            size="2x" 
            spin
        ></fa-icon>
        <span
            v-if="!saving"
        >{{message}}</span>
    </ion-button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';

export default defineComponent({
    name: 'LoadingButton',
    components: {
        IonButton,
    },
    props: {
        message: {
            type: String,
            default: 'Please wait...',
        },
        size:{
            type: String,
            default: 'default',
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        saving:{
            type: Boolean,
            default: false,
        },
    },
    setup(){
        const brand = process.env.VUE_APP_BRANDING;
        const themeColor = computed(() => 'var(--color-'+brand+'-button-primary)');
        return {
            themeColor,
        }
    }
})
</script>

<template>
    <ion-page>
        <div class="grid h-full w-full grid-cols-12 overflow-hidden">

            <authentication-sidescreen></authentication-sidescreen>

            <div class="col-span-12 md:col-span-6 py-20 px-4 max-h-screen overflow-y-scroll flex flex-col justify-center items-center">
                <form @submit.prevent="login" class="w-full sm:w-96">
                    <div class="px-4 lg:px-8 pb-20 mx-auto">
                        <!-- <figure>Logo</figure> -->
                        <img :src="'/assets/img/'+ brand +'/logo.svg'" class="pt-2 mx-auto" alt="brand-logo">
                        <img src="/assets/img/lawManagerLogo.svg" class="pt-2 w-48 mx-auto" alt="law-manager-logo">
                    </div>
                    <h2 class="text-txt-primary text-center my-10">Login to your account</h2>

                    <p class="text-center py-4 text-red-700" v-if="errorText">{{errorText}}</p>

                    <p class="text-txt-primary">Your email</p>
                    <ion-item lines="none" class="mb-6 mt-2">
                        <ion-input
                            type="email"
                            placeholder="Email"
                            v-model="email"
                        ></ion-input>
                    </ion-item>

                    <p class="text-txt-primary">Your password</p>
                    <ion-item lines="none" class="mb-6 mt-2">
                        <ion-input
                            type="password"
                            placeholder="Password"
                            v-model="password"
                            minlength="8"
                        ></ion-input>
                    </ion-item>

                    <p class="text-txt-secondary mt-3">
                        <a href="/forgot-password" class="text-sm underline">Forgot password</a>
                    </p>
                    <p class="mt-3 text-sm">
                        Don't have an account? <a href="/register" class="underline text-txt-secondary">Register here</a>
                    </p>

                    <button
                        type="submit"
                        expand="block"
                        class="rounded-lg py-4 w-full bg-btn-primary text-white mt-7"
                    >Login</button>

                    <button class="hidden" type="submit"></button>

                </form>
            </div>
        </div>
    </ion-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
    IonPage,
    IonLabel,
    IonItem,
    IonInput,
    IonButton,
} from '@ionic/vue';
import { injectStrict } from '@/utils/injectTyped';
import { AxiosKey } from '@/symbols';
import AuthenticationSidescreen from '@/components/portal/Auth/AuthenticationSidescreen.vue';

const brand = process.env.VUE_APP_BRANDING;
const axios = injectStrict(AxiosKey);
const email = ref('');
const password = ref('');
const errorText = ref('');
const login = async () => {
    if (email.value.length > 0 && password.value.length > 0) {
        errorText.value = '';
        await axios.post('/api/v1/accounts/start', {
            email: email.value,
            password: password.value,
            brand: brand,
        }, {
            withCredentials: true,
        }).then(() => {
            window.location.reload();
        }).catch(() => {
            errorText.value = 'Invalid credentials';
        });
    } else {
        errorText.value = 'Please enter your email and password';
    }
}

</script>
